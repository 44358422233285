<template>
  <div class="rounded-sm bg-white p-4 mb-4 d-flex flex-column">
    <p class="font-size-sm font-weight-bold mb-4">
      {{ $t('sparkmembers.members-details.enrollment-details.access-history.components.contents.title') }}
    </p>
    <b-table :fields="fields" :items="contents" small bordered responsive>
      <template #cell(updated_at)="data">
        <div class="text">
          {{ getDataFormatted(data.item.updated_at) }}
        </div>
      </template>
      <template #cell(title)="data">
        <div class="text">
          {{ data.item.lesson.title }}
        </div>
      </template>
    </b-table>
    <MButton
      v-if="canLoadMore"
      :label="btnLabel"
      variant="link"
      size="md"
      :icon="isLoading ? 'circle-notch fa-spin' : null"
      :disabled="isLoading"
      @click.prevent="$emit('loadMoreContents')"
      class="underline--hover font-size-sm align-self-center"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'Contents',
  components: {
    MButton: () => import('@/shared/components/MButton.vue'),
  },
  props: {
    contents: {
      type: Array,
    },
    canLoadMore: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    btnLabel: {
      type: String,
      default: 'Carregar mais',
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'updated_at',
          label: this.$t(
            'sparkmembers.members-details.enrollment-details.access-history.components.contents.table.updated-at'
          ),
          tdClass: 'updated-at',
        },
        {
          key: 'lesson.title',
          label: this.$t(
            'sparkmembers.members-details.enrollment-details.access-history.components.contents.table.lesson-title'
          ),
        },
      ],
    };
  },
  methods: {
    getDataFormatted(date) {
      return dayjs(date).format('DD/MM/YYYY [às] HH:mm');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .table {
    border-collapse: inherit;
    border-spacing: 0;
    @media (max-width: 767px) {
      width: 100vw;
      overflow: scroll;
    }
  }

  .updated-at {
    width: 160px;
  }

  .table thead th {
    background-color: #e3e3e3;
    border-color: #cfcfcf;
    font-weight: bold;
    text-align: center;
    color: #262626;
    padding: 8px;
    font-size: 14px;
    text-transform: initial;
    text-wrap: nowrap;
  }

  .table tbody td {
    border-color: #cfcfcf;
    padding: 16px 8px;
    word-break: break-all;

    &:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  @media (min-width: 768px) {
    .table thead tr,
    .table tbody tr {
      box-shadow: none;
    }
  }
}
</style>
